@import '@/styles/artifacts.scss';























































































































.trigger {
  display: flex !important;
  margin-bottom: 1px;

  input {
    width: 40%;
    display: inline-flex;
    margin-left: 30px;
    font-weight: 600;
  }

  .calendar-icon {
    margin-left: -2rem;
    margin-bottom: -2px;
  }
}

.month-picker {
  box-shadow: none;
  &.start-date,
  &.end-date {
    position: relative;
    ::v-deep .calendar-icon {
      position: absolute;
      top: 8px;
      right: 10px;
    }
    a {
      input {
        width: auto;
        margin: 0;
        padding: 8px;
        height: auto;
        font-weight: normal;
      }
    }
  }
}

.picker,
.picker ::v-deep .month-picker__year {
  box-shadow: none;
}

.picker ::v-deep .month-picker__year {
  background-color: transparent;

  button {
    background-color: transparent;
    border: none;

    &:hover {
      background-color: colorVodafone(lighter);
    }
  }
}

.picker ::v-deep .month-picker {
  border-radius: 0;
  box-shadow: none;
  border: none;

  .month-picker__month {
    border-radius: 0;
    box-shadow: none;
    border-color: transparent;

    &:not(.inactive).selected {
      background-color: colorVodafone(vodafone-red);
    }

    &:not(.inactive):hover {
      border-color: colorVodafone(vodafone-red);
    }

    &::after {
      width: 100%;
      height: 100%;
    }
  }
}

.picker.disable-btn ::v-deep .month-picker__year button {
  pointer-events: none;
  color: #e8e8e8;
}
