@import '@/styles/artifacts.scss';














































.column-filter-icon {
  display: block;
}
.selection {
  max-height: 75vh;
  overflow: auto;
}
