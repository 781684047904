@import '@/styles/artifacts.scss';




























































































































.summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.role {
  width: 60px;
  height: 60px;
}
.manufacturer {
  width: 120px;
  height: 60px;
}
