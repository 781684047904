@import '@/styles/artifacts.scss';























































.editable-value {
  .not-editable {
    display: flex;
    width: 100%;
    align-items: baseline;

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .pencil-icon {
      padding-left: 8px;
      width: 12px;
      height: 12px;
    }

    &:hover {
      cursor: pointer;
      transform: translate(0%, 0%) scale(1.03);
      transition-duration: 0.1s;
    }
  }
  .editable {
    input {
      width: 100%;
    }
  }
}
