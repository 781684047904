@import '@/styles/artifacts.scss';
























































.tooltip {
  z-index: 10000;
  background-color: #ffffff;
}
.tooltip .tooltip-inner {
  box-sizing: border-box;
  padding: 16px;
  position: relative;
  max-width: 80vw;
  box-shadow: 0 2px 8px rgba(0, 2, 70, 0.24);
  height: 100%;
  max-height: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  color: colorVodafone(black);
}
.tooltip .tooltip-arrow {
  position: absolute;
  width: 23px;
  height: 23px;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px -2px rgba(0, 2, 70, 0.24);
  transform-origin: center;
}
.tooltip[x-placement='top'] .tooltip-arrow {
  bottom: -11px;
  transform: rotate(45deg);
}
.tooltip[x-placement='right'] .tooltip-arrow {
  left: -11px;
  transform: rotate(135deg);
}
.tooltip[x-placement='bottom'] .tooltip-arrow {
  top: -11px;
  transform: rotate(225deg);
}
.tooltip[x-placement='left'] .tooltip-arrow {
  right: -11px;
  transform: rotate(315deg);
}
