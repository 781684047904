@import '@/styles/artifacts.scss';
























.bar {
  display: flex;
  flex-wrap: nowrap;
  height: 14px;
}

.no-data {
  text-align: center;
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-reset);
  padding: 1px 0;
  width: 100%;
  background-color: colorVodafone(light-grey);
  color: colorVodafone(dark-grey);
}
