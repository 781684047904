@import '@/styles/artifacts.scss';





























.apartment-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 15px 6px 15px;
  box-sizing: border-box;
  &:nth-child(odd) {
    background-color: colorVodafone(light-grey);
  }
}
.sensors {
  display: flex;
  flex-wrap: wrap;
}
// TODO Get rid of star selector
.sensors > * {
  margin: 4px;
}
