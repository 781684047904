@import '@/styles/artifacts.scss';




































.content-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.back-link {
  flex: 0 0 auto;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.image {
  display: block;
}
.arrow {
  stroke: colorVodafone(vodafone-red);
}
.back-link:hover .arrow {
  stroke: colorVodafone(vodafone-red-mouseover);
}
.right {
  flex: 1 0 auto;
  margin-top: -5px;
  margin-bottom: -4px;
}
.headline {
  margin: 0;
  color: colorVodafone(black);
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-xl);
  font-weight: fontWeight(regular-bold);
}
.suffix {
  font-weight: fontWeight(regular);
  color: colorVodafone(grey);
}
.description,
.breadcrumb {
  padding: 0;
  margin: 0;
  color: colorVodafone(grey);
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-50);
}
.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
