@import '@/styles/artifacts.scss';

























.title {
  margin: 0;
  font-size: fontSize(fs-150);
  font-weight: fontWeight(regular);
}
