@import '@/styles/artifacts.scss';



































.page-size {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;

  .select {
    width: 100px;
  }

  & .control {
    display: inline-block;
    width: 1.5ch;
  }
}
