@import '@/styles/artifacts.scss';













/* mechanism taken from https://css-tricks.com/custom-list-number-styling/  */
ol {
  list-style: none;
  counter-reset: steps-counter;
}
ol > li {
  margin: 0 0 0.5rem 0;
  counter-increment: steps-counter;
  position: relative;
}

ol > li::before {
  content: counter(steps-counter);
  color: red;
  font-weight: bold;
  border-radius: 50%;
  border: 1px solid colorVodafone(black);
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: fontWeight(regular-bold);
  color: colorVodafone(vodafone-red);
  position: absolute;
  left: calc(-1 * 20px - 10px);
}
