@import '@/styles/artifacts.scss';





































































































.ui-counter {
  display: flex;
}
.button {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  user-select: none;
}
.wrapper {
  flex: 1 0 0;
  overflow: hidden;
  text-align: center;
}
.label {
  margin-bottom: 3px;
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-25);
  @include textOverflow();
}
.value {
  display: flex;
  justify-content: center;
}
.icon-container {
  margin-right: 6px;
  height: 19px;
  padding: 2px 0;
}
.icon {
  display: block;
  width: auto;
  height: 100%;
}
.digit {
  position: relative;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) inset;
  margin: 0 2px;
  width: 18px;
  height: 23px;
  background-color: rgba(238, 238, 238, 0.5);
  font-family: 'Arial';
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-xl);
  color: colorVodafone(grey);
}
.RED {
  color: colorVodafone(vodafone-red);
}
.BLUE {
  color: colorVodafone(aqua-blue);
}
.DARK {
  color: colorVodafone(dark-grey);
}
.PURPLE {
  color: colorVodafone(red-violet);
}
.ORANGE {
  color: colorVodafone(fresh-orange);
}
.separator::before {
  position: absolute;
  content: ',';
  right: 100%;
}
