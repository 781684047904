@import '@/styles/artifacts.scss';






















.link {
  text-decoration: underline;
}
.link:hover {
  color: colorVodafone(vodafone-red);
}
.icon {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  vertical-align: baseline;
  margin-left: 0.3em;
  background-color: colorVodafone(black);
  mask: center/contain no-repeat;
}
.icon.before {
  margin-left: 0;
  margin-right: 0.3em;
}
.link:hover .icon {
  background-color: colorVodafone(vodafone-red);
}
