@import '@/styles/artifacts.scss';



























































































.label {
  padding: 12px 0 4px;
  @include textOverflow();
}

.bar {
  height: 8px;
}
.bar-track {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  background-color: colorVodafone(light);
}
.bar-track::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}
.bar-corner {
  flex: 0 0 auto;
  width: 8px;
}
.bar-space {
  flex: 1 0 auto;
}
.bar.GREEN {
  background-color: colorVodafone(digital-green-active);
}
.bar.RED {
  background-color: colorVodafone(vodafone-red);
}
.bar.ORANGE {
  background-color: colorVodafone(fresh-orange);
}

.ruler {
  position: relative;
  margin: 4px 0 0;
  height: 12px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.ruler-segment {
  position: absolute;
  top: 6px;
  height: 6px;
  width: 1px;
  background-color: colorVodafone(light);
}
.ruler-segment.primary {
  top: 0;
  height: 12px;
}

.ruler-labels {
  position: relative;
  height: 1.5em;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.ruler-label {
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  color: colorVodafone(grey);
}
