@import '@/styles/artifacts.scss';





































.replaced {
  display: block;
  appearance: none;
}
