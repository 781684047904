@import '@/styles/artifacts.scss';




















.ui-bubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 38px;
  width: 76px;
  height: 76px;
  background-color: colorVodafone(white);
  text-align: center;
}
.content {
  font-size: fontSize(fs-300);
  line-height: lineHeight(lh-25);
}
.caption {
  margin-bottom: -6px;
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-reset);
  color: colorVodafone(grey);
}
