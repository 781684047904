@import '@/styles/artifacts.scss';








































































.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 950;
  overflow: hidden;
}
.fade {
  transition: opacity 0.15s linear;

  &:not(.show) {
    opacity: 0;
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 940;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: 0.5;
  }
}
.modal-dialog {
  position: relative;
  margin: 10px;
  max-width: calc(100vw - 20px);
  .modal.fade & {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
  .modal.show & {
    transform: none;
  }
  @include media-breakpoint-up(sm) {
    width: fit-content;
    margin: 1.5rem auto;
  }
}
.modal-content {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  background: #ffffff;
}
.modal-open {
  overflow: hidden;
  .modal {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  #app {
    filter: blur(3px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
#app {
  transition: filter 0.15s linear;
}
