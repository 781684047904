@import '@/styles/artifacts.scss';






























































.grid-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 20px;
}
.cell {
  z-index: 10;
}
.scale {
  height: 100%;
  width: 100%;
}
.cell,
.scale {
  transition: all 0.6s;
}
.grid-list-enter {
  z-index: 5;
}
.grid-list-enter,
.grid-list-leave-to {
  opacity: 0;
}
.grid-list-enter > .scale,
.grid-list-leave-to > .scale {
  transform: scale(0);
}
.grid-list-leave-active {
  position: absolute;
  z-index: 0;
}
