@import '@/styles/artifacts.scss';

























































































































































































button {
  margin-top: 10px;
  width: 65px;
  height: 25px;
  border-radius: 3px;
  border: none;
  background-color: colorVodafone(vodafone-red);
  color: colorVodafone(white);
}
.multiselect {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.18);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.18);
  width: calc(100% + 4px);
  margin: 0 -2px;
  border-radius: 4px;
  border: 2px solid #ffffff;
  padding: 10px;
  background-color: #ededed;
  font-weight: 700;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  min-height: 350px;
}
.selected {
  background-color: #c5c5c5;
}
h4 {
  color: colorVodafone(vodafone-red);
}
ul {
  padding: 0;
  li {
    list-style-type: none;
    cursor: pointer;
  }
}
.buttons-container {
  margin-top: 25px;
  max-height: 150px;
}
