@import '@/styles/artifacts.scss';



















































































.manufacturers {
  display: grid;
  grid-template-columns: repeat(3, 120px);
  grid-auto-rows: 60px;
  grid-gap: 14px;
  margin-bottom: 20px;
}
