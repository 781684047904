@import '@/styles/artifacts.scss';






















































































.flatpickr-date-input {
  position: relative;
  padding: 0 !important;
  .input-text {
    height: 34px;
  }
  .icon {
    position: absolute;
    cursor: pointer;
    &.clear {
      top: 8px;
      right: 36px;
    }
    &.calendar {
      top: 8px;
      right: 12px;
    }
  }
}
.flatpickr-weekdays,
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
span.flatpickr-weekday {
  background-color: white;
}
