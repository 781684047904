@import '@/styles/artifacts.scss';
















.container {
  overflow: hidden;
  border-radius: 3px;
  height: 100%;
}
