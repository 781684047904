@import '@/styles/artifacts.scss';


















.image {
  width: 17px;
  height: 12px;
  margin-right: 3px;
}
.GREEN .icon {
  fill: colorVodafone(digital-green);
}
.RED .icon {
  fill: colorVodafone(vodafone-red);
}
