@import '@/styles/artifacts.scss';








































































.binding-wizard {
  padding: 40px 30px;
}
.header {
  margin-bottom: 5px;
  display: flex;
}
.title {
  margin: 0 auto 0 0;
  @include textOverflow();
  color: colorVodafone(black);
  font-size: fontSize(fs-200);
  line-height: lineHeight(lh-reset);
}
.close-button {
  outline: none;
  margin-left: 5px;
  border: none;
  padding: 0;
  background: transparent;
}
