@import '@/styles/artifacts.scss';













.spot-widget-data {
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-reset);
  text-align: center;
  @include textOverflow();
}
