@import '@/styles/artifacts.scss';























































.head,
.smile {
  &.green {
    stroke: colorVodafone(digital-green);
  }
  &.yellow {
    stroke: colorVodafone(lemon-yellow);
  }
  &.red {
    stroke: colorVodafone(vodafone-red);
  }
}
.eye {
  &.green {
    fill: colorVodafone(digital-green);
  }
  &.yellow {
    fill: colorVodafone(lemon-yellow);
  }
  &.red {
    fill: colorVodafone(vodafone-red);
  }
}
