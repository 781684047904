@import '@/styles/artifacts.scss';





































































.node {
  display: flex;
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-100);
}
.label {
  @include textOverflow();
}
.label::before {
  content: '+';
  display: inline-block;
  margin-left: 0.5em;
  width: 0.75em;
}
.label.open::before {
  content: '-';
}
.label:not(.disabled):hover {
  color: colorVodafone(vodafone-red);
}
