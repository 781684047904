@import '@/styles/artifacts.scss';




































































.selected {
  background-color: #c5c5c5;
}
h4 {
  margin: 0;
  cursor: pointer;
}
