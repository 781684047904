@import '@/styles/artifacts.scss';



































.input-file {
  display: block;
}
