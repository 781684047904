@import '@/styles/artifacts.scss';


























.label {
  margin: 0 0.2em;
  border-radius: 3px;
  padding: 0.1em 0.4em;
  background-color: colorVodafone(aqua-blue);
  color: colorVodafone(vodafone-white);
  font-weight: fontWeight(regular);
  white-space: nowrap;
}
.label.red {
  background-color: colorVodafone(vodafone-red);
}
.label.orange {
  background-color: colorVodafone(fresh-orange);
}
.label.green {
  background-color: colorVodafone(digital-green-active);
}
.label.grey {
  background-color: colorVodafone(grey);
}
