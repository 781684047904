@import '@/styles/artifacts.scss';












.ui-panel-separator {
  margin: 10px -20px;
  border: none;
  height: 1px;
  background-color: colorVodafone(light);
}
