@import '@/styles/artifacts.scss';


































































.text-input {
  margin-bottom: 20px;
  color: colorVodafone(black);
  font-size: fontSize(fs-150);
  line-height: lineHeight(lh-100);
}
.label {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.error-message {
  color: colorVodafone(vodafone-red);
}
.input {
  display: block;
  box-sizing: border-box;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.6);
  width: calc(100% + 4px);
  height: 48px;
  margin: 0 -2px;
  border-radius: 3px;
  border: 2px solid colorVodafone(light-grey);
  padding: 10px;
  transition: all 0.3s;
}
.input:focus {
  border-color: colorVodafone(vodafone-red);
  outline: none;
  background-color: colorVodafone(white);
}
textarea.input {
  height: 80px;
  resize: none;
}
.input.error {
  border-color: colorVodafone(voadfone-red);
}
.tooltip-icon {
  border-radius: 50%;
  color: colorVodafone(white);
  line-height: lineHeight(lh-reset);
  min-width: 16px;
  min-height: 16px;
  background: colorVodafone(vodafone-red);
  margin-left: 10px;
  font-weight: bolder;
  font-size: fontSize(fs-base);

  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
