@import '@/styles/artifacts.scss';









































.ui-inline-list {
  display: inline;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.item {
  display: inline;
}
.item:not(:last-child)::after {
  content: ',';
}
