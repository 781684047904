@import '@/styles/artifacts.scss';





























.controls {
  min-height: 30px;
  display: flex;
  margin-top: 18px;
  align-items: stretch;
}
.arrow {
  display: flex;
  align-items: center;
}
.next {
  margin-left: auto;
}
.icon {
  transition: 0.3s all;
}
.inactive .icon {
  stroke: #abadb0;
}
