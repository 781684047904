@import '@/styles/artifacts.scss';




























.ui-icon-state-indicator {
  width: 25px;
  height: 25px;
}
