@import '@/styles/artifacts.scss';












































.nowrap {
  white-space: nowrap;
}
