@import '@/styles/artifacts.scss';













.hint {
  color: colorVodafone(black);
  font-size: fontSize(fs-base);
  margin: 0 0 30px 0;
}
