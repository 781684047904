@import '@/styles/artifacts.scss';






































.title {
  font-size: fontSize(fs-200);
}

.message {
  font-size: fontSize(fs-150);
}

.icon-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 5px solid colorVodafone(black);
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    color: colorVodafone(black);
    font-size: 34px;
    font-weight: 900;
  }
}
