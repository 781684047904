@import '@/styles/artifacts.scss';










































$duration: 300ms;

.accordion-cell {
  flex: 1 0 0;
  box-sizing: border-box;
  max-width: 100%;
  padding: 10px;
}
.accordion-enter-active,
.accordion-leave-active {
  transition: opacity $duration, padding $duration, height $duration;
}
.accordion-enter,
.accordion-leave-to {
  opacity: 0;
  padding: 0 10px;
  height: 0;
}
.inner {
  transform-origin: top;
}
.accordion-enter-active > .inner,
.accordion-leave-active > .inner {
  transition: transform $duration;
}
.accordion-enter > .inner,
.accordion-leave-to > .inner {
  transform: scale(0);
}
