@import '@/styles/artifacts.scss';






























.title {
  font-size: fontSize(fs-200);
}
.content {
  position: fixed;
  top: 76px;
  right: 0;
  height: calc(100vh - 76px);
  z-index: 99;
  border: 1px solid colorVodafone(vodafone-grey);
  padding: 20px;
  background: colorVodafone(white);
  width: 80vw;
  max-width: 800px;
  overflow: auto;
  box-sizing: border-box;
}
.close {
  height: 16px;
  width: 16px;
}
.show-transition-enter-active {
  transition: all 0.3s ease;
}
.show-transition-leave-active {
  transition: all 0.3s ease;
}
.show-transition-enter {
  transform: translateX(100%);
}
.show-transition-leave-to {
  opacity: 0;
}
