@import '@/styles/artifacts.scss';








































.item {
  margin-right: 14px;
  margin-bottom: 14px;
  .input-hidden {
    position: absolute;
    left: -9999px;
    & + label > .label-inner {
      border: 2px solid transparent;
    }
    &:checked + label > .label-inner {
      &.blue {
        border-color: colorVodafone(aqua-blue);
      }
      &.red {
        border-color: colorVodafone(vodafone-red);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22);
      }
    }
  }
  .label-inner {
    transition: 0.3s all;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    &.label-md {
      width: 56px;
    }
    &.label-lg {
      width: 116px;
    }
    &.label-sm {
      height: 41px;
      width: 41px;
    }
  }
}
