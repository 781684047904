@import '@/styles/artifacts.scss';




































































































































































































@import '~ol/ol.css';

.ui-map {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.map {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: -1px;
  left: 0;
}
.content {
  display: none;
}
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5em 2em;
  font-size: fontSize(fs-300);
  text-align: center;
  color: colorVodafone(white);
  background: #0006;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transition: opacity 0.2s linear;
}
.wheel > .overlay.wheel-zoom,
.touch > .overlay.touch-pan {
  opacity: 1;
}
