@import '@/styles/artifacts.scss';













.cross-fade-enter-active,
.cross-fade-leave-active {
  transition: opacity 300ms;
}
.cross-fade-enter,
.cross-fade-leave-to {
  opacity: 0;
}
