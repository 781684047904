@import '@/styles/artifacts.scss';










































.chart-style-switch {
  padding: 10px 5px;

  .icon:hover {
    // #8C8C8C color using filter
    filter: invert(69%) sepia(30%) saturate(0%) hue-rotate(291deg) brightness(77%) contrast(79%);
  }
  .line-align {
    padding-top: 1px;
  }
}
