@import '@/styles/artifacts.scss';

















.label {
  margin: 0 0 25px 0;
  color: colorVodafone(black);
  font-size: fontSize(fs-base);

  line-height: lineHeight(lh-reset);
}
.label.error {
  color: colorVodafone(vodafone-red);
}
