@import '@/styles/artifacts.scss';






























.ui-icon {
  width: 25px;
  height: 25px;
}
