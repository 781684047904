@import '@/styles/artifacts.scss';
















































































.roles {
  display: grid;
  grid-template-columns: repeat(4, 60px);
  grid-auto-rows: 60px;
  grid-gap: 14px;
  margin-bottom: 20px;
}
