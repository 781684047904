@import '@/styles/artifacts.scss';








































.label {
  padding: 0 0 4px;
  @include textOverflow();
}

.bar {
  height: 8px;
}
.bar-track {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  background-color: colorVodafone(light);
}
.bar-track::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}
.bar.GREEN {
  background-color: colorVodafone(digital-green-active);
}
.bar.RED {
  background-color: colorVodafone(vodafone-red);
}
.bar.ORANGE {
  background-color: colorVodafone(fresh-orange);
}

.bar-labels {
  display: flex;
  justify-content: space-between;
  padding: 2px 8px 5px;
  font-size: fontSize(fs-50);
}
.bar-label {
  flex: 0 0 auto;
  color: colorVodafone(grey);
}
.bar-label.GREEN {
  color: colorVodafone(digital-green-active);
}
.bar-label.RED {
  color: colorVodafone(vodafone-red);
}
.bar-label.ORANGE {
  color: colorVodafone(fresh-orange);
}
