@import '@/styles/artifacts.scss';

























.selectable-button {
  height: 29px;
  border-radius: 15px;
  padding: 1px 30px 0 30px;
  border: none;
  outline: none;
  font-size: fontSize(fs-base);
  color: colorVodafone(white);
  line-height: lineHeight(lh-reset);
}
.red {
  background-color: colorVodafone(vodafone-red);
  &.active {
    background-color: colorVodafone(dark-red);
  }
}
.green {
  background-color: colorVodafone(digital-green);
  &.active {
    background-color: colorVodafone(digital-green-active);
  }
}
.blue {
  background-color: colorVodafone(aqua-blue);
  &.active {
    background-color: colorVodafone(turquoise);
  }
}
