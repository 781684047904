@import '@/styles/artifacts.scss';






























































.wizard-modal-wrapper {
  padding: 20px;
  @include media-breakpoint-up(sm) {
    padding: 40px 30px;
  }
}
.header {
  margin-bottom: 20px;
  display: flex;
}
.title {
  margin: 0 auto 0 0;
  @include textOverflow();
  color: colorVodafone(dark);
  font-size: fontSize(fs-150);
  line-height: lineHeight(lh-rest);
}
.close-button {
  outline: none;
  margin-left: 5px;
  border: none;
  padding: 0;
  background: transparent;
}
