@import '@/styles/artifacts.scss';










































































































































































































































































































.ui-image-canvas {
  position: relative;
  box-sizing: border-box;
  padding: 60px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: 0 0/10px 10px repeat
      linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.0980392) 25%,
        transparent 25%,
        transparent 75%,
        rgba(0, 0, 0, 0.0980392) 75%,
        rgba(0, 0, 0, 0.0980392) 0
      ),
    5px 5px/10px 10px repeat
      linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.0980392) 25%,
        transparent 25%,
        transparent 75%,
        rgba(0, 0, 0, 0.0980392) 75%,
        rgba(0, 0, 0, 0.0980392) 0
      ),
    white;
  user-select: none;
}
.row {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.column {
  position: relative;
  flex: 0 0 auto;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.canvas {
  --scale: 1;
  position: relative;
  flex: 0 0 auto;
  max-height: 100%;
  cursor: move;
  transition: 0.1s left, 0.1s top;
}
.canvas.moving {
  transition: none;
  touch-action: none;
}
.image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  transform: scale(var(--scale), var(--scale));
  transform-origin: center;
  transition: 0.1s transform;
}

.zoom {
  position: absolute;
  top: 16px;
  left: 16px;
}
.zoom-button {
  display: block;
  margin-bottom: 4px;
  border: 1px solid colorVodafone(light-grey);
  width: 32px;
  height: 32px;
  background-color: colorVodafone(white);
  font-size: fontSize(fs-200);
  color: colorVodafone(dark-grey);
}
.zoom-button:hover {
  background-color: colorVodafone(vodafone-red);
  color: colorVodafone(white);
}

.placement {
  --x: 0;
  --y: 0;
  position: absolute;
  // since we only scale transform .image and not .canvas we need to apply
  // the coordinate transformations ourselves in order to correctly position
  // the placements according to the scale
  // 1. convert to image coordinates with center origin: cp = 2 * p - 1
  // 2. scale: scp = p * s
  // 3. back to ground plan coordinates: tp = (scp + 1) / 2
  // 4. convert to percentages: rp = tp * 100% (in the calc below this simplified into the previous step)
  left: calc(((2 * var(--x) - 1) * var(--scale) + 1) * 50%);
  top: calc(((2 * var(--y) - 1) * var(--scale) + 1) * 50%);
  z-index: 1;
  transform: translate(-50%, -50%);
  transition: 0.1s left, 0.1s top;
}
.placement:hover {
  z-index: 2;
}
.placement[draggable='true'] {
  cursor: grab;
}
