@import '@/styles/artifacts.scss';



































































































































































.selection {
  margin: 0;
  padding: 0;
  padding-bottom: 0.5em;
  border-bottom: 1px solid colorVodafone(light);
  margin-bottom: 0.5em;
  list-style-type: none;
}
