@import '@/styles/artifacts.scss';




















.expand {
  width: 16px;
  height: 16px;
  margin: 0 6px 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    &.expanded {
      transform: rotate(180deg);
    }
    &.expanded.right {
      transform: rotate(0deg);
    }
    &.right {
      transform: rotate(-90deg);
    }
  }
}
