@import '@/styles/artifacts.scss';





















.ui-headline {
  color: colorVodafone(black);
  font-weight: fontWeight(regular);
  font-size: fontSize(fs-150);
  line-height: lineHeight(lh-reset);
  margin: 0;
}
