@import '@/styles/artifacts.scss';































































.arrow {
  display: flex;
  align-items: center;
  user-select: none;
}
.icon {
  margin-right: 0.3em;
  transform: rotate(-90deg);
}
.collapsed {
  transform: rotate(0deg);
}
