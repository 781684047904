@import '@/styles/artifacts.scss';

































































.input-radio {
  position: relative;
  display: inline-block;
  padding-left: 1.5em;
  min-height: 1.5em;
  color: colorVodafone(vodafone-dark-grey);
  font-weight: fontWeight(regular);
  line-height: lineHeight(lh-100);
  cursor: pointer;
  user-select: none;
}
.input-radio.disabled {
  cursor: inherit;
  opacity: 0.5;
}

.radio {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.radiomark {
  position: absolute;
  top: 0.15em;
  left: 0;
  box-sizing: border-box;
  border-radius: 50%;
  height: 1.2em;
  width: 1.2em;
  background-color: colorVodafone(shaded);
}
.radio:checked ~ .radiomark {
  background-color: colorVodafone(vodafone-red);
}
.radio:indeterminate:not(.implicit) ~ .radiomark {
  background-color: colorVodafone(vodafone-red);
}
.radio.implicit:not(.checked) ~ .radiomark {
  background-color: colorVodafone(vodafone-red);
  opacity: 0.5;
}
.radio.hidden ~ .radiomark {
  visibility: hidden;
}

.radio:checked ~ .radiomark::after,
.radio.implicit ~ .radiomark::after {
  content: '';
  position: absolute;
  top: 0.15em;
  left: 0.15em;
  display: block;
  box-sizing: border-box;
  border: 0.3em solid colorVodafone(white);
  border-radius: 50%;
  background-color: colorVodafone(vodafone-red);
  width: 0.9em;
  height: 0.9em;
}
.radio:indeterminate:not(.implicit) ~ .radiomark::after {
  content: '';
  position: absolute;
  top: 0.15em;
  left: 0.15em;
  display: block;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  background-color: colorVodafone(white);
  width: 0.9em;
  height: 0.9em;
}
