@import '@/styles/artifacts.scss';





















.item {
  font-size: fontSize(fs-base);
  line-height: lineHeight(lh-50);
}
.label {
  padding: 0;
  text-align: right;
}
.value {
  box-sizing: border-box;
  padding: 0 0 0 5px;
  word-break: break-all;
  color: colorVodafone(grey);
}
