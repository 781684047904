@import '@/styles/artifacts.scss';

















.icon-read-indicator .dot {
  stroke: transparent;
  fill: colorVodafone(vodafone-red);
  transition: all 0.3s linear;
}
.icon-read-indicator.read .dot {
  stroke: colorVodafone(grey);
  fill: transparent;
}
