@import '@/styles/artifacts.scss';



































































.no-options {
  color: colorVodafone(grey);
}

.selection-button {
  font-size: fontSize(fs-50);
  margin-right: 12px;
}

.inputs {
  &.inline {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
